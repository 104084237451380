// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/home/arrow-left.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/home/arrow-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".yusu-acne-title[data-v-47a5a8df]{padding:.67708rem 0;display:flex;justify-content:center;align-items:center;font-size:.26042rem}.yusu-tailor-made-solutions .swiper-button-prev[data-v-47a5a8df]:after,.yusu-tailor-made-solutions .swiper-container-rtl .swiper-button-next[data-v-47a5a8df]:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");cursor:pointer}.yusu-tailor-made-solutions .swiper-button-prev[data-v-47a5a8df],.yusu-tailor-made-solutions .swiper-container-rtl .swiper-button-next[data-v-47a5a8df]{left:.40104rem;right:auto}.yusu-tailor-made-solutions .swiper-button-next[data-v-47a5a8df],.yusu-tailor-made-solutions .swiper-container-rtl .swiper-button-prev[data-v-47a5a8df]{right:.40104rem;left:auto}.yusu-tailor-made-solutions .swiper-button-next[data-v-47a5a8df]:after,.yusu-tailor-made-solutions .swiper-container-rtl .swiper-button-prev[data-v-47a5a8df]:after{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");cursor:pointer}", ""]);
// Exports
module.exports = exports;
