<template>
  <div class="yusu-tailor-made-solutions">
    <section>
      <img :src="bg01" alt="" />
    </section>
    <section>
      <img :src="bg02" alt="" />
    </section>
    <section>
      <img :src="bg03" alt="" />
    </section>
    <section>
      <div class="yusu-acne-title">为你提供针对性解决方案</div>
      <swiper class="yusu-top-detail-left-swiper" :options="swiperOptionVideo">
        <swiper-slide v-for="(item, index) in bannerImgList" :key="index">
          <img :src="item" alt="" />
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>
    <section>
      <img :src="bg05" alt="" />
    </section>
    <section>
      <img :src="bg06" alt="" />
    </section>
    <section>
      <img :src="bg07" alt="" />
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'
import banner1 from '../../assets/images/tailor-made-solutions/repairSensitive/banner-01.jpg'
import banner2 from '../../assets/images/tailor-made-solutions/repairSensitive/banner-02.png'
import bg01 from '../../assets/images/tailor-made-solutions/repairSensitive/bg-01.jpg'
import bg02 from '../../assets/images/tailor-made-solutions/repairSensitive/bg-02.png'
import bg03 from '../../assets/images/tailor-made-solutions/repairSensitive/bg-03.png'
import bg05 from '../../assets/images/tailor-made-solutions/repairSensitive/bg-05.png'
import bg06 from '../../assets/images/tailor-made-solutions/repairSensitive/bg-06.png'
import bg07 from '../../assets/images/tailor-made-solutions/repairSensitive/bg-07.png'

export default {
  name: 'tailor-made-solutions-sensitive',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data: () => {
    return {
      bannerImgList: [banner1, banner2],
      swiperOptionVideo: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      bg01: bg01,
      bg02: bg02,
      bg03: bg03,
      bg05: bg05,
      bg06: bg06,
      bg07: bg07,
    }
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.yusu-acne-title {
  padding: 130px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}
.yusu-tailor-made-solutions {
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: url('../../assets/images/home/arrow-left.png');
    cursor: pointer;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 77px;
    right: auto;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 77px;
    left: auto;
  }
  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: url('../../assets/images/home/arrow-right.png');
    cursor: pointer;
  }
}
</style>
